var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filesData.length > 0
    ? _c(
        "div",
        [
          _vm._l(_vm.filesData, function(file) {
            return _c(
              "form-element",
              {
                key: file.id,
                attrs: {
                  label: _vm.$_.get(file, "label", ""),
                  items: [
                    "Proof of Income",
                    "Current ID Verification",
                    "Other"
                  ],
                  required: file.required
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mb-3", attrs: { color: "grey lighten-5" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("form-file-upload", {
                          attrs: {
                            file: file,
                            "upload-api-path": _vm.uploadApiPath,
                            "max-upload-size": _vm.$config(
                              "applications.maxUploadSize"
                            ),
                            hint: _vm.$_.get(file, "hint", ""),
                            applicant_id: _vm.applicant_id
                          },
                          on: { update: _vm.updateFile }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c("psi-action-drop-down", {
                    attrs: {
                      large: "",
                      "btn-class": "secondary",
                      "prepend-icon": "mdi-file-plus",
                      "btn-label": "Add File",
                      heading: "File Types",
                      items: _vm.items
                    },
                    on: { action: _vm.fileAction }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }