<template>
    <div v-if="filesData.length > 0">
        <form-element
            v-for="file in filesData"
            :key="file.id"
            :label="$_.get(file, 'label', '')"
            :items="['Proof of Income', 'Current ID Verification', 'Other']"
            :required="file.required"
        >
            <v-card color="grey lighten-5" class="mb-3">
                <v-card-text>
                    <form-file-upload
                        :file="file"
                        :upload-api-path="uploadApiPath"
                        :max-upload-size="$config('applications.maxUploadSize')"
                        :hint="$_.get(file, 'hint', '')"
                        :applicant_id="applicant_id"
                        @update="updateFile"
                    ></form-file-upload>
                </v-card-text>
            </v-card>
        </form-element>

        <v-row>
            <v-col cols="12" class="text-right">
                <psi-action-drop-down
                    large
                    btn-class="secondary"
                    prepend-icon="mdi-file-plus"
                    btn-label="Add File"
                    heading="File Types"
                    :items="items"
                    @action="fileAction"
                ></psi-action-drop-down>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import FormFileUpload from "../../Form/FormFileUpload";

export default {
    name: "applicant-files",
    components: {
        FormFileUpload,
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
        applicant_id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            filesData: [],
            uploadApiPath: process.env.VUE_APP_APPLY_API_HOST + "/api/upload",
            items: [
                {
                    title: this.$t("applications.proof_of_income_label"),
                    label: this.$t("applications.proof_of_income_label"),
                    required: false,
                    icon: "mdi-file-percent",
                    name: "proof_of_income",
                    filename: `income`,
                    file: null,
                    id: "",
                },
                {
                    title: this.$t("applications.id_verification_label"),
                    label: this.$t("applications.id_verification_label"),
                    required: false,
                    icon: "mdi-card-account-details",
                    name: "id_verification",
                    filename: `identification`,
                    file: null,
                    id: "",
                },
                {
                    title: this.$t("applications.other_label"),
                    label: this.$t("applications.other_label"),
                    icon: "mdi-file-table",
                    required: false,
                    name: "other",
                    file: null,
                    id: "",
                    filename: `other`,
                },
            ],
        };
    },
    watch: {
        files: {
            immediate: true,
            handler(files) {
                this.filesData = files
                    .map((file) => {
                        (file.hint = this.$t(
                            `applications.${file["name"]}_hint`
                        )),
                            (file.label = this.$t(
                                `applications.${file["name"]}_label`
                            ));
                        return file;
                    })
                    .slice();
                if (this.validate()) {
                    this.$emit("valid");
                }
            },
        },
    },
    methods: {
        fileAction(item) {
            item.id = this.$uuid.v4();
            item.filename += `_${Math.random()
                .toString(36)
                .substr(2, 5)
                .toUpperCase()}`;
            this.filesData = this.filesData.concat(
                this.$_.pick(item, [
                    "label",
                    "required",
                    "name",
                    "file",
                    "filename",
                    "id",
                ])
            );
        },
        updateFile(id, data = null) {
            this.filesData.find((file) => file.id === id)["file"] = data;
            // Update the Applicant with the uploaded or cleared file
            this.$emit("update", this.filesData);
        },
        validate() {
            return this.filesData.every((file) => {
                return (
                    !file.required ||
                    (file.required &&
                        file.file !== null &&
                        Object.keys(file.file).length > 0)
                );
            });
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>