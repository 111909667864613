<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="form" class="mb-4">
                    <v-file-input
                        :id="file.filename"
                        :name="file.filename"
                        @click:clear="clearFile"
                        @change="fileUpload(file.filename)"
                        :placeholder="`Upload your ${file.label}`"
                        :hint="file.hint"
                        :clearable="!loading"
                        :disabled="loading"
                        :error="uploadError"
                        persistent-hint
                        :prepend-icon="
                            $vuetify.breakpoint.mdAndUp
                                ? 'attachment'
                                : 'mdi-camera'
                        "
                        accept="image/png, image/jpg, image/jpeg, application/pdf, application/docx, application/doc"
                        chips
                        :counter="$vuetify.breakpoint.mdAndUp"
                        dense
                        :rules="[
                            (v) => !!v || `${file.label} is required`,
                            (v) =>
                                (v && v.size < maxUploadSize) ||
                                'File must be smaller than 10 MB',
                        ]"
                        :show-size="$vuetify.breakpoint.mdAndUp"
                        :loading="loading"
                        :value="$_.get(file, 'file', null)"
                        :ref="file.filename"
                    >
                        <template v-slot:selection="{ text }">
                            <v-chip small label color="primary">
                                <v-icon small class="mr-1" v-if="uploadSuccess"
                                    >mdi-check-bold</v-icon
                                >
                                {{ text }}
                            </v-chip>
                        </template>
                        <template v-slot:progress>
                            <v-progress-linear
                                color="primary"
                                :value="uploadProgress"
                            ></v-progress-linear>
                        </template>
                    </v-file-input>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    name: "form-file-upload",
    components: {
        //  FileUpload
    },
    props: {
        file: {
            type: Object,
            required: true,
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        applicant_id: {
            type: String,
            required: true,
        },
        maxUploadSize: {
            type: Number,
            requried: true,
        },
        uploadApiPath: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            uploadProgress: 0,
            uploadSuccess: false,
            uploadError: false,
        };
    },
    computed: {},
    methods: {
        initialize() {
            this.$refs.form.reset();
            this.uploadProgress = 0;
            this.uploadSuccess = false;
            this.loading = false;
            this.uploadError = false;
        },
        clearFile() {
            this.initialize();
            this.$emit("update", this.file.id, null);
        },

        fileUpload(fileName) {
            this.uploadError = false;
            let file = this.$refs[fileName].$refs.input.files[0];
            if (this.$refs.form.validate()) {
                this.uploadProgress = 0;
                this.loading = true;
                if (file) {
                    this.$vapor
                        .store(file, {
                            progress: (progress) => {
                                this.uploadProgress = Math.round(
                                    progress * 100
                                );
                            },
                        })
                        .then((response) => {
                            axios
                                .post(this.uploadApiPath, {
                                    uuid: response.uuid,
                                    key: response.key,
                                    bucket: response.bucket,
                                    name: file.name,
                                    content_type: file.type,
                                    extension: response.extension,
                                    applicant_id: this.applicant_id,
                                    field: this.file.name,
                                })
                                .then((response) => {
                                    this.loading = false;
                                    this.uploadSuccess = true;
                                    const data = {
                                        lastModified: file.lastModified,
                                        size: file.size,
                                        name: file.name,
                                        type: file.type,
                                        bucket: response.data.bucket,
                                        key: response.data.key,
                                        url: response.data.url,
                                        status: response.data.status,
                                        data: {
                                            _token: this.csrf_token,
                                            field: this.file.name,
                                            applicant_id: this.applicant_id,
                                        },
                                    };
                                    this.$emit("update", this.file.id, data);
                                })
                                .catch((error) => {
                                    this.initialize();
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            this.loading = false;
                            console.error(error);
                        });
                }
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>


        