var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                { ref: "form", staticClass: "mb-4" },
                [
                  _c("v-file-input", {
                    ref: _vm.file.filename,
                    attrs: {
                      id: _vm.file.filename,
                      name: _vm.file.filename,
                      placeholder: "Upload your " + _vm.file.label,
                      hint: _vm.file.hint,
                      clearable: !_vm.loading,
                      disabled: _vm.loading,
                      error: _vm.uploadError,
                      "persistent-hint": "",
                      "prepend-icon": _vm.$vuetify.breakpoint.mdAndUp
                        ? "attachment"
                        : "mdi-camera",
                      accept:
                        "image/png, image/jpg, image/jpeg, application/pdf, application/docx, application/doc",
                      chips: "",
                      counter: _vm.$vuetify.breakpoint.mdAndUp,
                      dense: "",
                      rules: [
                        function(v) {
                          return !!v || _vm.file.label + " is required"
                        },
                        function(v) {
                          return (
                            (v && v.size < _vm.maxUploadSize) ||
                            "File must be smaller than 10 MB"
                          )
                        }
                      ],
                      "show-size": _vm.$vuetify.breakpoint.mdAndUp,
                      loading: _vm.loading,
                      value: _vm.$_.get(_vm.file, "file", null)
                    },
                    on: {
                      "click:clear": _vm.clearFile,
                      change: function($event) {
                        return _vm.fileUpload(_vm.file.filename)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(ref) {
                          var text = ref.text
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  small: "",
                                  label: "",
                                  color: "primary"
                                }
                              },
                              [
                                _vm.uploadSuccess
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v("mdi-check-bold")]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(text) + " ")
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "progress",
                        fn: function() {
                          return [
                            _c("v-progress-linear", {
                              attrs: {
                                color: "primary",
                                value: _vm.uploadProgress
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }